.team-grid{
    /* display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px; */
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.team-card{
    width: 30%;
    /* border: 1px solid; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.team-head{
    padding: 15px;
    /* border-radius: 10px; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ededed;
}
.team-head p{
    margin:0;
    font-size: 18px;
    color: #252525    ;
}

.team-body{
    padding: 15px;
}

.team-wrapper{
    margin-bottom: 10px;
}