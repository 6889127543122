@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-remove-capital{
  text-transform: none!important;
  font-weight: bold!important;
}
.btn-rounded{
  border-radius: 20px!important;
}
.remove-boxshadow{
  font-weight: 600!important;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
  /* box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important; */

}
.btn-flat-height{
  height: 25px!important;
  padding-bottom:0px!important;
  padding-top:0px!important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
.icon-table{
  width:16px ;
  margin-left: 24px;
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

nav{
    width:100%;
    height: 64px;
    /* border:1px solid black; */
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    z-index: 999;
    position: fixed;
    top:0;
    align-items: center;
    padding: 0 50px 0 30px;
}
.logo{
    width:500px;
    /* border:1px solid red; */
    align-self: center;
    justify-content: space-around;
    align-items: center;
    display: flex;
}


.vertical-line{
    width:2px;
    height: 50px;
    background-color: #ccc;
}
.logo img{
    width: 40px;
}
.logo h4{
    color: rgb(128, 128, 128);
    font-size: 20px;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: -0.06px;
}
.profile{
    width: 170px;
    cursor: pointer;
    /* border:1px solid green; */
    /* text-align: center; */
}
.profile img{
    width: 40px;
    border-radius: 100%;
    height: 40px;
    object-fit: cover;
}
.hamburger{
    width:50px;
    /* border:1px solid black; */
    height: 40px;
    background-color: transparent;
    display: none;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.hamburger:hover{
    background-color: white;
}
.hamburger__list{
    width: 40px;
    height: 2px;
    background-color: black;
}

.search{
    width: 790px;
    border-radius: 4px;
    align-items: center;
    padding: 2px;
    display: flex;
    border-radius: 34px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: #ffffff;
}
.search img{
    margin-left: 10px;
    margin-right: 10px;
    width: 20px;
}
.search-field{
    width: 100%;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.05px
}
@media screen and (max-width:768px){
    .hide-mobile{
        display: none!important;
    }
    .hamburger{
        display: flex;
    }
    nav{
        height: 100px;
        display: none!important;
        
    }
    .logo{
        align-content: center;
    }
}
.sidebar-wrapper{
    width: 232px;
    height: 100vh;
    position: fixed;
    z-index: 3;
    /* border:1px solid black; */
    padding-top: 100px;
    background-color: white;
}
.icon-size{
    width: 20px;
}
.list-sidebar{
    /* border:1px solid black; */
    margin: 10px 10px 20px 25px;
    /* height: 30px; */
    padding:5px 0px 5px 10px;
    text-decoration: none;
    display: flex;
    border-radius: 19px;
    cursor: pointer;
    
}
.active-sidebar{
     /* border:1px solid black; */
     margin: 10px 10px 20px 25px;
     /* height: 30px; */
     padding:5px 0px 5px 10px;
     text-decoration: none;
     display: flex;
     border-radius: 19px;
     cursor: pointer;
     background-color: #f2f6ff;
}
.active-sidebar a{
    font-family: Open Sans, sans-serif;
    text-decoration: none;
    color: #777777;
    font-size: 14px;
    font-weight: bold;
}
.list-sidebar:hover{
    background-color: #f2f6ff;
}
.list-sidebar a{
    font-family: Open Sans, sans-serif;
    text-decoration: none;
    color: #777777;
    font-size: 14px;
    font-weight: bold;
}
.content-wrapper{
    /* min-height: 700vh; */
    /* position: relative; */
    /* border:1px solid black; */
    margin-left: 215px;
    padding: 65px 35px 15px 35px;
    /* background-color: #f5f5f5; */
    background-color: #f8f8f8

;
}
.content-wrapper-pipeline{
    min-height: 80vh;
    /* position: relative; */
    /* border:1px solid black; */
    margin-left: 200px;
    padding: 65px 9px 15px 35px;
    /* background-color: #f5f5f5; */
    background-color: #f8f8f8

;
}
@media screen and (max-width:768px){
    .content-wrapper{
        display: none;
    }
    .content-wrapper-pipeline{
        display: none;
    }
}
.modal{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 5000;

    position: fixed!important;
}
.modal-profile{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    border-radius: 10px;
    z-index: 5000;
    margin-top: 20px;
    margin-right: 20px;
    /* height: 200px; */
    position: fixed!important;
    padding:10px
}
.ck-editor__editable:not(.ck-editor__nested-editable) {
    max-height: 300px;
}
.modal-content{
    /* width:400px; */
    outline: 0px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    

    /* overflow-y: scroll; */
}
.modal-header{
    /* border:1px solid black; */
    padding-left:20px;
    padding-right:20px;
    
    /* height: 50px; */;
    display: flex;
    justify-content: space-between;
    /* border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium; */
    margin-bottom: 5px;
}
.modal-header img{
    cursor: pointer;
}
.modal-header h2{
    font-family: 'Lato', sans-serif;
    color: #252525;
    font-size: 20px;
    margin-bottom: 5px;
}
.modal-body{
    /* width: 50%; */
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    /* min-height: 10px; */
    max-height: 495px;
    overflow: auto;
    overflow-x: hidden;
    /* border:1px solid black */
    
}
.modal-body-filter{
    /* width: 50%; */
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    /* min-height: 10px; */
    max-height: 495px;
    /* overflow: auto; */
    /* overflow-x: hidden; */
    /* border:1px solid black */
    
}
.modal-content{
    
}
.modal-hr{
    border:1px dashed  #ccc;
}
.modal__field{
    width: 100%;
}


.profile-container{
    /* border:1px solid black */
    text-align: center;
}
.profile-container h2{
    font-family: Open Sans, sans-serif;
    color: #252525;
    font-size: 18px;
    margin-bottom: 0;
}
.profile-container p{
    font-family: Open Sans, sans-serif;
    color: #777777;
    font-size: 14px;
    margin-top: 0;

}

.rm-filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rm-filter-by{
    display: flex;
    align-items: center;
    margin-top: 0px!important;

}
.rm-filter-by p{
    margin-top: 0px!important;
    margin-bottom: 0px!important;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
}
.rm-filter-field{
    width: 170px;
}
.contact-field{
    width: 140px;
}
.modal-footer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding:15px 5px 20px 20px
}
.rm-table{
    max-height: 300px;
    overflow-y: scroll;
}
.rm-table::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE and Edge */
  .rm-table {
    -ms-overflow-style: none;
  }

  .confirm-container,.alert-container{
    padding:10px;
    text-align: center;
}
.card-footer{
    display: flex;
    justify-content: flex-end;
    padding:20px
}
.deals-wrapper{
    /* max-height: 470px; */
    /* overflow-y: scroll; */
}
.deals-wrapper::-webkit-scrollbar {
    /* display: none; */
  }
  
  /* Hide scrollbar for IE and Edge */
  .deals-wrapper {
    /* -ms-overflow-style: none; */
  }
.add-deals-wrapper{
    display: flex;

}
.add-deals-step{
    width: 90px;
    height: 40px;
    border-width:1px 1px 1px 0px;
    border-style: solid;
    border-color:  rgba(34,36,38,.15);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-deals-step p{
    margin: 0;
    color:#777777;
    /* font-weight: 600; */
    font-size: 11px;
}
.add-deals-step:first-of-type{
    border-left: 1px solid rgba(34,36,38,.15);
}
.add-deals-step:after{
    position: absolute;
    z-index: 2;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #fff;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
.add-deals-wrapper .add-deals-step:last-of-type:after{
    display: none;
}
.add-deals-step-active{
    width: 90px;
    height: 40px;
    border-width:1px 1px 1px 0px;
    border-style: solid;
    border-color:  rgba(34,36,38,.15);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #70bf4e;
}
.add-deals-step-active p{
    margin: 0;
    color:#777777;
    /* font-weight: 600; */
    font-size: 11px;
}
.add-deals-step-active:first-of-type{
    border-left: 1px solid rgba(34,36,38,.15);
}
.add-deals-step-active:after{
    position: absolute;
    z-index: 2;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #70bf4e;
    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
.add-deals-wrapper .add-deals-step-active:last-of-type:after{
    display: none;
}
.add-deals__field{
    width: 100%!important;
    /* height: 40px!important; */
    margin-bottom: 15px!important;
}


.add-proposal__field{
    width: 100%;
    margin-bottom: 15px!important;
}

@media screen and (max-width:768px){
    .modal-content{
        width:100%;
        outline: 0px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
    }
    .modal-header h2{
        font-size: 18px;
    }
    .modal{
        /* align-items: flex-end; */
    }
}
.loading-wrapper{
    position: fixed;
    z-index: 500;
    height: 100vh;
    width: 100%;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
}
.head-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    /* border:1px solid black */
}
.head-section h4{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
}
.head-section h3{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
}
.head-add-section__btn{
    /* box-shadow: none!important; */
    text-transform: none!important;
    border-radius: 20px!important;
    height: 30px;
    min-width:80px!important;
    margin:0 0 0 20px!important;
   
}
.head-section__btn{
    height: 30px !important;
    border-radius: 24px!important;
    text-transform: none!important;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
    width:80px;
    font-weight: bold!important;
    
}

.card-content{
    min-height: 20vh;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    
    display: flex;
    flex-direction: column;
}

.card-table{
    /* margin-top: 20px; */
    padding:20px

}
.card-table__head{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.card-table__head_btn{
    padding:0px!important;
    background-color: transparent;
    color: #3b99eb;
    cursor: pointer;
    border:0;
    font-weight: bold;
    outline: none;
    margin:10px;
    display: flex;
    align-items: center;
}
.icon-action{
    width:15px;
    height: 15px;
    margin:5px 2px 5px 15px;
    cursor: pointer;
}


.add-client-top{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-left:20px;
    padding-right:20px;
    padding-top: 20px;
}

.bold{
    font-weight: bold;
    color: #6a7267;
    margin:0;
    font-size: 14px;
}
.semi-bold{
    /* font-weight: bold; */
    color: #777777;
    font-size: 18px;
  font-weight: 600;
    margin-top:0;
    font-size: 14px;
  font-weight: 600;
  /* margin:5px; */
}
.semi-bold-nomargin{
    /* font-weight: bold; */
    color: #777777;
    font-size: 18px;
  font-weight: 600;
  margin:0;
    font-size: 14px;
  font-weight: 600;
  /* margin:5px; */
}

.form-request{
    width: 90%;
    margin-bottom: 15px!important;
}
.field-radius{
    border-radius: 10px!important;

}
.hr{
    width: 100%;
    margin:auto;
    height: 1px;
    background-color: #BBBBBB;
}
.add-client-bottom{
    display: block;
    /* width: 100%; */
    padding:20px;
    /* border:1px solid black */
}

.remove-capital{
    text-transform: none!important;
    font-weight: 600!important;
}
.card-table__pagination{
    display: flex;
    justify-content: flex-end;
}
.card-pagination{
    font-family: 'Open Sans', sans-serif;
    /* font-size: 12px; */
    margin:0
}
.file-title{
    display: flex;
}
.file-title b{
    color: rgb(128, 128, 128);
    font-size: 14px;
}
.file-title h6{
    color: rgb(128, 128, 128);
    font-size: 14px;
    font-weight: normal;
}

.login-wrapper{
    padding:0px;
    margin:0px;
    background-image: url(/static/media/Background.1b452126.png);
    background-size:100% 100vh;
    height: 99vh;
    background-repeat: no-repeat;
    border:1px solid white;
    /* width: 100%; */
}
.login-form{
    width: 400px;
    height: 430px;
    /* border:1px solid green; */
    /* margin-left: 99px;
    margin-top: 70px; */
    margin:8%
}
.login-form h1{
    color: #3B99EB;
    font-family: 'Open Sans', sans-serif;

}
.login-form p{
    color: #808080;
}
.login-form h3{
    font-size: 24px;
    color: #808080;
    margin-top: 50px;
}
.form-login{
    margin-bottom: 10px!important;
    width: 260px;
  height: 40px!important;

    /* border:1px solid black!important */
}
.btn-login{
    text-transform: none!important;
    width:260px;
    border-radius: 24px!important;
  box-shadow: 0 3px 6px 0 rgba(233, 30, 99, 0.2)!important;
}
/* .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 13px) scale(1)!important;
    pointer-events: none;
} */
.pipeline-wrapper{
    display: flex;
    /* position: absolute; */
    /* left: 220px; */
    margin-bottom: 70px;
}

.pipeline-step{
    width: 100%;
    height: 50px;
    border:1px solid rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
}
.pipeline-step p{
    margin: 0;
    margin-left: 10px;
    color: #777777;
    font-weight: bold;
    z-index: 4;
}
.pipeline-step:after{
    position: absolute;
    z-index: 2!important;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #f8f8f8;;
    width: 35px;
    height: 35px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
.pipeline-step-last{
    width: 100%;
    height: 50px;
    border:1px solid rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f8;
}
.pipeline-step-last p{
    margin: 0;
    margin-left: 10px;
    color: #777777;
    font-weight: bold;
    z-index: 4;
}
.pipeline-container{
    width: 100%;
    /* position: absolute; */
}
.pipeline-deals{
    padding:20px 0px 20px 18px;
}

.deals-card{
    /* position: absolute; */
    margin-bottom: 10px;
    /* color: red; */
    width: 95%;
    /* height: 90px; */
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    /* cursor: pointer; */
    position: relative;
    min-height: 30px;
    /* display: 'flex';
    justify-content: center;
    align-items: center;
    border:1px solid black; */
}
.deals-card-content{
    padding:10px;
    
}
.deals-card-title{
    color: #3B99EB;
    margin:0;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}
.proposal-value{
    position: relative;
    display: inline-block;
}
.deals-card-company{
    margin:5px 0px 5px 0px;
    font-weight: 600;
    font-size: 10px;
    color: #777777;
}
.circle-chevron{
    /* position: absolute; */
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 5px;
}
.deals-card-footer{
    display: flex;
    justify-content: space-between;
}
.deals-card-footer p{
    margin: 0;
    /* font-size: 10px; */
    /* color: #777777; */
    font-weight: 600;
}
.modal-deals{
    top:-10px;
    left: 180px;
    position: absolute;
    width: 230px;
    min-height: 100px;
    /* border:1px solid black; */
    z-index: 3;
    background-color: #ffffff;
    border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* padding-left:20px  */
}
.modal-deals-left{
    top:-10px;
    left: -230px;
    position: absolute;
    width: 230px;
    min-height: 100px;
    /* border:1px solid black; */
    z-index: 2;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* padding-left:20px  */
}
.modal-deals-item h3{
    color:#777777;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
}
.modal-deals-item{
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 20px;
    border-bottom:1px solid #eeeeee;
    cursor: pointer;
}
.modal-deals-footer{
    display: flex;
    padding:10px
}
.pipeline-footer{
    width: 100%;
    height: 30px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 230px;
    padding:16px 0px 16px 30px;
    display: flex;
    align-items: center;
}
.pipeline-footer p{
    font-size: 18px;
    font-weight: 600;
    color: #777777;
}
.modal-count-probability{
    width:360px;
    /* height: 60px; */
    bottom: 50px;
    left:480px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
    z-index: 10;
}

.detail-header{
    width: 80%;
    /* height: 200px; */
    background-color: white;
    position: fixed;
    top:0px;
    /* bottom: 0; */
    left: 230px;
    padding:90px 0px 16px 20px;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    /* border:1px solid black */
    /* display: flex; */
    /* align-items: center; */
}
.header-title{
    margin: 0;
    align-items: center;
    display: flex;
    font-weight: bold;
    margin-bottom: 15px;
}

.header-menu{
    display: flex;
    /* border:1px solid black; */
    width: 98%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.header-data{
    display: flex;
}
.header-data p{
    margin: 0 ;
    align-items: center;
    display: flex;
    margin-right: 30px;
    font-size: 12px;
    color: #252525    ;
    font-weight: 600;
    max-width: 100px;
}
.header-data-icon{
    width:15px;
}
.header-btn{
    display: flex;
    /* border:1px solid black; */
}

.header-step-wrapper{
    display: flex;
    /* width: 80%; */
}
.header-step{
    width: 100%;
    height: 30px;
    border-width:1px 1px 1px 0px;
    border-style: solid;
    border-color:  white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #70bf4e;
}
.header-step p{
    margin: 0;
    color:#777777;
    font-weight: 600;
    font-size: 11px;
}
.header-step:first-of-type{
    border-left: 1px solid rgba(34,36,38,.15);
}
.header-step:after{
    position: absolute;
    z-index: 2;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #70bf4e;

    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: white;
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
.header-step-wrapper .header-step:last-of-type:after{
    display: none;
}



.detail-content{
    margin-top:150px;
    display: flex;
    /* grid-template-columns: repeat(2,1fr); */
    /* padding:20px; */
    /* border:1px solid black */
}

.detail-card,.detail-jurnal{
    padding:10px;
    /* border:1px solid black */
}
.detail-jurnal{
    width: 60%;
}
.detail-card{
    width: 40%;
}
.detail-card-wrapper{
    margin-bottom: 20px;
    width: 95%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.detail-card-header{
    padding:10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
}
.detail-card-header h3{
    margin:0;
    font-size: 18px;
    color:#aaaaaa;
    font-weight: 600;
}

.detail-card-body{
    padding:15px 20px 10px 20px;
}
.detail-card-body p{
    /* margin-bottom: 5px; */
}
.card-header-icon{
    width:18px;
    height:18px;
    cursor: pointer;
}
.card-proposal-footer{
    background-color: #70bf4e;
    padding:10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
}
.card-proposal-footer p{
    margin:0;
    font-size: 18px;
    color: white;
    font-weight: 600;

}

.jurnal-header{
    padding:5px 20px 5px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
}
.jurnal-header h3{
    margin:0;
    font-size: 18px;
    color:#aaaaaa;
    font-weight: 600;
}

.jurnal-wrapper{
    /* padding:10px; */
    /* height: 500px; */
    position: relative;
}
.jurnal-wrapper:after{
    /* width:4px;
    content: '';
    position: absolute;
    background-color: #eeeeee;
    top:0;
    bottom: 0; */
}
.jurnal-item:last-of-type{
    /* background-color:red; */

}
.jurnal-item{
    /* height: 100px; */
    /* border:1px solid black; */
    position: relative;
    padding-bottom: 10px;
}
.jurnal-item:after{
    content: '';
    width:24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color:#ffffff;
    top:-1px;
    left:-9px;
    z-index: 1;
}
.jurnal-item:last-of-type::before{
    display: none;
}
.jurnal-item::before{
    content: '';
    width:4px;
    height: 100%;
    position: absolute;
    /* border-radius: 50%; */
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
    background-color:#eeeeee;
    /* top:-1px;
    left:-9px; */
    z-index: 1;
}
.jurnal-item-content{
    margin-left: 40px;
}

.jurnal-item-content-default{
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

.sub-jurnal-item-content-default{
    font-size: 10px;
    font-weight: normal;
    color: #cccccc;
    display: flex;
    align-items: center;
}

.btn-jurnal-invoice{
    width: 90px!important;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
    height: 25px!important;
    font-size: 12px!important;
    padding:0!important;
    font-weight: 600!important;
}

.text-jurnal-green{
    color:#70bf4e;
}
.jurnal-item-proposal{
    width: 100%;
    /* height: 200px; */
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin-bottom: 20px;
}
.card-header-item-jurnal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 15px 0px 15px;
    /* border:1px solid black */
}
.card-header-item-jurnal-subtitle{
    color: #777777;
    margin-top:0;
    font-size: 12px;
}
.card-content-item-jurnal{
    
    padding:0px 15px 0px 15px;


}
.card-content-item-jurnal-text{
    color: #777777!important;
    margin:2px!important;
    font-size: 13px!important;
    font-weight: 600!important;
    padding:0!important
}
.card-content-item-jurnal-text-without-weight{
    color: #777777!important;
    margin:0!important;
    font-size: 13px!important;
    font-weight: normal!important;
    padding:0!important
}

.card-footer-item-jurnal{
    
    padding:3px 3px 0px 15px;
    background-color: #70bf4e;
    bottom: 0;
    height: 30px;
    border-radius: 0px 0px 10px 10px;
}
.card-footer-item-jurnal p{
    color:white!important;
    margin:0!important;
    font-size: 16px!important;
    font-weight: 600!important;
    padding:0!important;
}
.card-footer-item-jurnal-meeting{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
    padding:10px 10px 10px 15px;
    background-color: #fff7dd;
    bottom: 0;
    /* height: 30px; */
    border-radius: 0px 0px 10px 10px;
}
.card-footer-item-jurnal-meeting p{
    color:#777777!important;
    margin:0!important;
    font-size: 10px!important;
    font-weight: 600!important;
    padding:0!important;
}
.pipeline-filterby{
    font-size: 12px;
    margin:3px;
    color: #252525;
}

.tooltip-text{
    top:-50px;
    left:-50px;
    width:250px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    border-radius: 5px;
    padding:5px;
    z-index: 10;
    /* visibility: hidden; */
    display: none;
}
.tooltip-text:after{
    content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}
.tooltip-text-proposal-value{
    top:-35px;
    left:-30px;
    min-width:100px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    border-radius: 5px;
    padding:5px;
    z-index: 10;
    color: #4e8637;
    font-weight: bold;
    /* visibility: hidden; */
    display: none;
}
.tooltip-text-proposal-value:after{
    content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}
.deals-card-title:hover .tooltip-text{
    /* display: none; */
    /* visibility: visible; */
    display: block;
}
.proposal-value:hover .tooltip-text-proposal-value{
    display: block;
}
.success-drag{
    width: 180px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeOut ease 3s;
  -webkit-animation: fadeOut ease 3s;
  -moz-animation: fadeOut ease 3s;
  -o-animation: fadeOut ease 3s;
  -ms-animation: fadeOut ease 3s;   
}
@keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }

  .modal-title-change{
      
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px;
        width:400px;
        top:115px;
        z-index: 10000;
  }
  .modal-probability-change{
      
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px;
        width:400px;
        top:165px;
        z-index: 10;
  }

.projection-card-footer{
    /* padding:5px ; */
    /* border:1px solid black; */
    width:170px;
    height: 30px;
    margin-left: 18px;
    position: fixed;
    bottom: 70px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #70bf4e;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.summary-wrapper{
    width:100%;
    /* height: 100px; */
    /* border:1px solid black; */
    background-color: #ffffff;
    border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.summary-header{
    /* border:1px solid black; */
    /* height: 50px; */
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
    display: flex;
    align-items: center;
    padding:15px 25px 15px 25px
}
.summary-header p{
    margin:0;
}
.summary-header h2{
    margin:0;
    font-size: 20px;
    /* padding:20px; */
    color: #777777;

}
.summary-table{
    display: flex;
}
.summary-table-cell{
    /* padding:10px 20px 0px 20px */
    padding:10px 25px 0px 25px
}
.summary-table-head{
    padding:10px 20px 0px 25px
}
.summary-table-price{
    font-size: 14px;
    margin:0
}
.summary-total{
    font-size: 14px;
    margin:0;
    font-size: 600;
    color: #777777;
    font-weight: bold;
}
.summary-table-probability{
    font-size: 12px;
    color: #777777;
    /* margin:0 */
}

.div-flex{
    display: flex;
    align-items: center;
}
.pipeline-wrapper{
    display: flex;
    /* position: absolute; */
    /* left: 220px; */
    margin-bottom: 70px;
}

.pipeline-step{
    width: 100%;
    /* width:198px; */
    height: 50px;
    /* border-right-width: 0px; */
    border:1px solid rgba(34,36,38,.15);
    position: -webkit-sticky;
    position: sticky;
    top:65px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* z-index: 1; */
    background-color: #f8f8f8;
    /* border:1px solid black; */


}
.pipeline-step p{
    margin: 0;
    margin-left: 10px;
    color: #777777;
    font-weight: bold;
    z-index: 4;
}
.pipeline-step:after{
    position: absolute;
    z-index: 2!important;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #f8f8f8;;
    /* background-color: black; */
    width: 35px;
    height: 35px;
    border-style: solid;
    border-color: rgba(34,36,38,.15);
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
    /* border-bottom: 10px solid red;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
    height: 10;
    border-bottom-style: solid;
	width: 1px;
    transform: translateX(60%) rotate(90deg); */
   
}

.pipeline-container{
    width: 100%;
    /* position: absolute; */
}
.pipeline-deals{
    padding:20px 0px 20px 18px;
}

.deals-card{
    /* position: absolute; */
    margin-bottom: 10px;
    /* color: red; */
    width: 95%;
    /* height: 90px; */
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    /* cursor: pointer; */
    position: relative;
    min-height: 30px;
    /* display: 'flex';
    justify-content: center;
    align-items: center;
    border:1px solid black; */
}
.deals-card-content{
    padding:10px;
    
}
.deals-card-title{
    color: #3B99EB;
    margin:0;
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;
    position: relative;
    display: inline-block;
}
.deals-card-company{
    margin:5px 0px 5px 0px;
    font-weight: 600;
    font-size: 10px;
    color: #777777;
}
.circle-chevron{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 5px;
}
.deals-card-footer{
    display: flex;
    justify-content: space-between;
}
.deals-card-footer p{
    margin: 0;
    /* font-size: 10px; */
    /* color: #777777; */
    font-weight: 600;
}
.modal-deals{
    top:-10px;
    left: 180px;
    position: absolute;
    width: 230px;
    min-height: 50px;
    /* border:1px solid black; */
    z-index: 3;
    background-color: #ffffff;
    border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* padding-left:20px  */
}
.modal-deals-left{
    top:-10px;
    left: -230px;
    position: absolute;
    width: 230px;
    min-height: 50px;
    /* border:1px solid black; */
    z-index: 2;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    /* padding-left:20px  */
}
.modal-deals-item h3{
    color:#777777;
    font-weight: 600;
    margin: 0;
    font-size: 16px;
}
.modal-deals-item{
    display: flex;
    align-items: center;
    padding: 15px 20px 15px 20px;
    border-bottom:1px solid #eeeeee;
    cursor: pointer;
}
.modal-deals-footer{
    display: flex;
    padding:10px
}
.pipeline-footer{
    width: 100%;
    height: 30px;
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 230px;
    padding:16px 0px 16px 30px;
    display: flex;
    align-items: center;
}
.pipeline-footer p{
    font-size: 18px;
    font-weight: 600;
    color: #777777;
}
.modal-count-probability{
    width:360px;
    /* height: 60px; */
    bottom: 50px;
    left:480px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px;
    z-index: 10;
}

.detail-header{
    width: 80%;
    /* height: 200px; */
    background-color: white;
    position: fixed;
    top:0px;
    /* bottom: 0; */
    left: 230px;
    padding:90px 0px 16px 20px;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    /* border:1px solid black */
    /* display: flex; */
    /* align-items: center; */
}
.header-title{
    margin: 0;
    align-items: center;
    display: flex;
    font-weight: bold;
    margin-bottom: 15px;
}

.header-menu{
    display: flex;
    /* border:1px solid black; */
    width: 98%;
    justify-content: space-between;
    margin-bottom: 15px;
}
.header-data{
    display: flex;
}
.header-data p{
    margin: 0 ;
    align-items: center;
    display: flex;
    margin-right: 30px;
    font-size: 12px;
    color: #252525    ;
    font-weight: 600;
    max-width: 100px;
}
.header-data-icon{
    width:15px;
}
.header-btn{
    display: flex;
    /* border:1px solid black; */
}

.header-step-wrapper{
    display: flex;
    /* width: 80%; */
}
.header-step{
    width: 100%;
    height: 30px;
    border-width:1px 1px 1px 0px;
    border-style: solid;
    border-color:  white;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #70bf4e;
}
.header-step p{
    margin: 0;
    color:#777777;
    font-weight: 600;
    font-size: 11px;
}
.header-step:first-of-type{
    border-left: 1px solid rgba(34,36,38,.15);
}
.header-step:after{
    position: absolute;
    z-index: 2;
    content: '';
    top: 50%;
    right: 0;
    border: medium none;
    background-color: #70bf4e;

    width: 10px;
    height: 10px;
    border-style: solid;
    border-color: white;
    border-width: 0 1px 1px 0;
    transition: background-color .1s ease,opacity .1s ease,color .1s ease,box-shadow .1s ease;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}
.header-step-wrapper .header-step:last-of-type:after{
    display: none;
}



.detail-content{
    margin-top:150px;
    display: flex;
    /* grid-template-columns: repeat(2,1fr); */
    /* padding:20px; */
    /* border:1px solid black */
}

.detail-card,.detail-jurnal{
    padding:10px;
    /* border:1px solid black */
}
.detail-jurnal{
    width: 60%;
}
.detail-card{
    width: 40%;
}
.detail-card-wrapper{
    margin-bottom: 20px;
    width: 95%;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
}
.detail-card-header{
    padding:10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
}
.detail-card-header h3{
    margin:0;
    font-size: 18px;
    color:#aaaaaa;
    font-weight: 600;
}

.detail-card-body{
    padding:15px 20px 10px 20px;
}
.detail-card-body p{
    /* margin-bottom: 5px; */
}
.card-header-icon{
    width:18px;
    height:18px;

    cursor: pointer;
}
.card-proposal-footer{
    background-color: #70bf4e;
    padding:10px 20px 10px 20px;
    display: flex;
    justify-content: space-between;
}
.card-proposal-footer p{
    margin:0;
    font-size: 18px;
    color: white;
    font-weight: 600;

}

.jurnal-header{
    padding:5px 20px 5px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
}
.jurnal-header h3{
    margin:0;
    font-size: 18px;
    color:#aaaaaa;
    font-weight: 600;
}

.jurnal-wrapper{
    /* padding:10px; */
    /* height: 500px; */
    position: relative;
}
.jurnal-wrapper:after{
    /* width:4px;
    content: '';
    position: absolute;
    background-color: #eeeeee;
    top:0;
    bottom: 0; */
}
.jurnal-item:last-of-type{
    /* background-color:red; */

}
.jurnal-item{
    /* height: 100px; */
    /* border:1px solid black; */
    position: relative;
    padding-bottom: 10px;
}
.jurnal-item:after{
    content: '';
    width:24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color:#ffffff;
    top:-1px;
    left:-9px;
    z-index: 1;
}
.jurnal-item:last-of-type::before{
    display: none;
}
.jurnal-item::before{
    content: '';
    width:4px;
    height: 100%;
    position: absolute;
    /* border-radius: 50%; */
    /* box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); */
    background-color:#eeeeee;
    /* top:-1px;
    left:-9px; */
    z-index: 1;
}
.jurnal-item-content{
    margin-left: 40px;
}

.jurnal-item-content-default{
    /* display: flex; */
    /* justify-content: space-between; */
    /* align-items: center; */
}

.sub-jurnal-item-content-default{
    font-size: 10px;
    font-weight: normal;
    color: #cccccc;
    display: flex;
    align-items: center;
}

.btn-jurnal-invoice{
    width: 90px!important;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35)!important;
    height: 25px!important;
    font-size: 12px!important;
    padding:0!important;
    font-weight: 600!important;
}

.text-jurnal-green{
    color:#70bf4e;
}
.jurnal-item-proposal{
    width: 100%;
    /* height: 200px; */
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin-bottom: 20px;
}
.card-header-item-jurnal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:10px 15px 0px 15px;
    /* border:1px solid black */
}
.card-header-item-jurnal-subtitle{
    color: #777777;
    margin-top:0;
    font-size: 12px;
}
.card-content-item-jurnal{
    
    padding:0px 15px 0px 15px;


}
.card-content-item-jurnal-text{
    color: #777777!important;
    margin:2px!important;
    font-size: 13px!important;
    font-weight: 600!important;
    padding:0!important
}
.card-content-item-jurnal-text-without-weight{
    color: #777777!important;
    margin:0!important;
    font-size: 13px!important;
    font-weight: normal!important;
    padding:0!important
}

.card-footer-item-jurnal{
    
    padding:3px 15px 0px 15px;
    background-color: #70bf4e;
    bottom: 0;
    height: 30px;
    border-radius: 0px 0px 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-footer-item-jurnal p{
    color:white!important;
    margin:0!important;
    font-size: 16px!important;
    font-weight: 600!important;
    padding:0!important;
}
.card-footer-item-jurnal-meeting{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 10px;
    padding:10px 10px 10px 15px;
    background-color: #fff7dd;
    bottom: 0;
    /* height: 30px; */
    border-radius: 0px 0px 10px 10px;
}
.card-footer-item-jurnal-meeting p{
    color:#777777!important;
    margin:0!important;
    font-size: 10px!important;
    font-weight: 600!important;
    padding:0!important;
}
.pipeline-filterby{
    font-size: 12px;
    margin:3px;
    color: #252525;
}

.tooltip-text{
    top:-50px;
    left:-50px;
    width:250px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    border-radius: 5px;
    padding:5px;
    z-index: 10;
    /* visibility: hidden; */
    display: none;
}
.tooltip-text:after{
    content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}
.deals-card-title:hover .tooltip-text{
    /* display: none; */
    visibility: visible;
}

.success-drag{
    width: 180px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeOut ease 3s;
  -webkit-animation: fadeOut ease 3s;
  -moz-animation: fadeOut ease 3s;
  -o-animation: fadeOut ease 3s;
  -ms-animation: fadeOut ease 3s;   
}
@keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes fadeOut {
    0% {
      opacity:1;
    }
    100% {
      opacity:0;
    }
  }

  .modal-title-change{
      
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px;
        width:400px;
        top:115px;
        z-index: 10000;
  }
  .modal-probability-change{
      
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:10px;
        width:400px;
        top:165px;
        z-index: 10;
  }

.projection-card-footer{
    /* padding:5px ; */
    /* border:1px solid black; */
    width:170px;
    height: 30px;
    margin-left: 18px;
    position: fixed;
    bottom: 70px;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #70bf4e;
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.summary-wrapper{
    width:100%;
    /* height: 100px; */
    /* border:1px solid black; */
    background-color: #ffffff;
    border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.summary-header{
    /* border:1px solid black; */
    /* height: 50px; */
    justify-content: space-between;
    border-bottom-style: dashed ;
    border-bottom-color: #ccc;
    border-bottom-width: medium;
    display: flex;
    align-items: center;
    padding:15px 25px 15px 25px
}
.summary-header p{
    margin:0;
}
.summary-header h2{
    margin:0;
    font-size: 20px;
    /* padding:20px; */
    color: #777777;

}
.summary-table{
    display: flex;
}
.summary-table-cell{
    /* padding:10px 20px 0px 20px */
    padding:10px 25px 0px 25px
}
.summary-table-head{
    padding:10px 20px 0px 25px
}
.summary-table-price{
    font-size: 14px;
    margin:0
}
.summary-total{
    font-size: 14px;
    margin:0;
    font-size: 600;
    color: #777777;
    font-weight: bold;
}
.summary-table-probability{
    font-size: 12px;
    color: #777777;
    /* margin:0 */
}
.head-tab{
    display: flex;
}
.head-tab li{
    list-style: none;
    /* border:1px solid black; */
    min-width: 60px;
    height: 20px;
    color: #777777;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    margin-right: 20px;
    text-align: center;
}
.head-tab-active{
    color: #3B99EB!important;
    border-bottom: 3px solid #3B99EB;
}

.card-invoice-grid{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-left:20px;
    padding-right:20px;
    padding-top: 5px;
}
.click-view{
    color: #3B99EB!important;
    cursor: pointer;
}
.report-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.report-header h4{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
}
.report-modal{
    z-index: 1000;
    top:30px;
    right:-10px;
    width: 200px;
    min-height: 100px;
    padding-left: 8px;
    padding-right: 8px;
    /* border:1px solid black; */
    background-color: white;
    position: absolute;
    border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.report-modal p{
    font-weight: bold;
    color: #252525;
    font-size: 15px;
    cursor: pointer;
}
.detail-header-report{
    width: 80%;
    /* height: 200px; */
    background-color: white;
    position: fixed;
    top:0px;
    /* bottom: 0; */
    left: 230px;
    padding:70px 0px 0px 20px;
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 2;
    /* border:1px solid black */
    /* display: flex; */
    /* align-items: center; */
}
.detail-content-report{
    margin-top:100px;
    /* display: flex; */
    /* grid-template-columns: repeat(2,1fr); */
    /* padding:20px; */
    /* border:1px solid black */
}
.card-wrapper-report{
    width: 100%;
    /* height: 100px; */
    /* border:1px solid black; */
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;

}
.card-report{
    /* height: 120px; */
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    padding: 15px;
}
.card-report h4{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    margin:15px;
    margin-left: 0px;
}
.color-green{
    color: #70bf4e;
}
.color-red{
    color: #ff6e79;
}
.color-yellow{
    color: #ffb100;
}
.grey-bold{
    /* font-weight: bold; */
    color: #cccccc;
    font-size: 18px;
  font-weight: 600;
    margin-top:0;
    font-size: 14px;
  font-weight: 600;
  margin:0px;
}

.div-flex{
    display: flex;
}
.div-space-between{
    justify-content: space-between;
}
.div-align-center{
    align-items: center;
    /* border:1px solid black; */
}

.invoice-table{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    padding: 15px 10px 15px 10px;
    /* border:1px solid black; */
}
.invoice-table-width{
    width: 25%;
    /* border:1px solid black; */
    padding-left: 5px;
}
.invoice-table-width p{
    margin-top: 10px;
    /* margin-bottom: 10px; */
}

.with-border{
    border-bottom: 1px solid #777777;

}
.with-border-top{
    border-top: 1px solid #777777;

}
.triangle{
    content: '';
    -webkit-clip-path: polygon(50% 23%, 15% 74%, 92% 73%);
            clip-path: polygon(50% 23%, 15% 74%, 92% 73%);

    background-color: #70bf4e;
}
.total-text{
    color: #252525;
    font-size: 16px;
  font-weight: 600;
    margin-top:0;
  font-weight: 600;
}
.graph-grid{
    width: 100%;
    /* height: 100px; */
    /* border:1px solid black; */
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 20px;
}

.graph-card{
    padding:20px;
    min-height: 100px;
    border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
  background-color: white;
}
.graph-tooltip{
    min-width: 150px;
    min-height: 70px;
    border-radius: 5px;
    /* border:1px solid black; */
}
.graph-tooltip-head{
    height: 20px;
    background-color: #eeeeee;
    text-align: center;
}
.graph-tooltip-body{
    padding:10px
}
.graph-tooltip-body p{
    border:1px solid black;
    margin:0;
    display: flex!important;
}
.bulat1{
    width:10px;
    height:10px;
    border-radius: 50%;
    background-color: #70bf4e;
    content: '';
}
.bulat2{

    width:10px;
    height:10px;
    border-radius: 50%;
    background-color: #3B99EB;
}
.apexcharts-toolbar{
    z-index: 1!important;
}

.head-title{
    color: #6a7267;
    /* font-size: 24px; */
    font-weight: 600;
    line-height: 1.38;
}

.card-account-wrapper-report{
    width: 100%;
    /* height: 100px; */
    /* border:1px solid black; */
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 20px;

}
.card-account-report{
    /* height: 120px; */
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
    background-color: white;
    padding: 15px;
}
.card-account-report h4{
    display: flex;
    font-size: 20px;
    font-weight: 600;
    margin:15px;
    margin-left: 0px;
}
.team-grid{
    /* display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px; */
    display: flex;
    grid-gap: 30px;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
}

.team-card{
    width: 30%;
    /* border: 1px solid; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(119, 119, 119, 0.35);
}
.team-head{
    padding: 15px;
    /* border-radius: 10px; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ededed;
}
.team-head p{
    margin:0;
    font-size: 18px;
    color: #252525    ;
}

.team-body{
    padding: 15px;
}

.team-wrapper{
    margin-bottom: 10px;
}
